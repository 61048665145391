/**
 * @file Careers Hero.
 */

import { gsap } from 'gsap';

/**
 * Careers Hero component.
 */
const vCareersHero = () => {
	const careersHero = document.querySelector('.v-careers-hero');

	if (!careersHero) {
		return;
	}

	const turbineBlades       = careersHero.querySelectorAll('.v-careers-graphic__turbine-blades');
	const computerPin         = careersHero.querySelector('.v-careers-graphic__floating-pin');
	const phonePin            = careersHero.querySelector('.v-careers-graphic__phone-pin');
	const prices              = careersHero.querySelectorAll('.v-careers-graphic__phone-price');
	const loading             = careersHero.querySelector('.v-careers-graphic__loading');
	const customer            = careersHero.querySelector('.v-careers-graphic__worker-phone');
	let activeIdx             = 0;

	/**
	 * Define timeline.
	 */
	const pinsTl     = gsap.timeline({
		defaults: {
			transformOrigin: '100% 50%',
			ease:            'power1.inOut',
			duration:        0.5,
		},
		repeat:   -1,
		onRepeat: () => {
			activeIdx = (activeIdx + 1) % prices.length;
			[...prices].forEach((price) => {
				price.removeAttribute('style');
			});
		},
	});

	/**
	 * Add tweens.
	 */
	pinsTl
		.from(computerPin, {
			y:       15,
			opacity: 0,
		})
		.to(computerPin, {
			x:       -65,
			opacity: 0,
		}, '3')
		.from(phonePin, {
			x:       15,
			opacity: 0,
			onStart: () => {
				gsap.from(prices[activeIdx], {
					duration:        0.5,
					transformOrigin: '100% 50%',
					ease:            'power1.inOut',
					x:               15,
					opacity:         0,
					onStart:         () => {
						prices[activeIdx].style.visibility = 'visible';
					},
				});
			},
		}, '4')
		.to(phonePin, {
			x:       -15,
			opacity: 0,
			onStart: () => {
				gsap.to(prices[activeIdx], {
					duration:        0.5,
					transformOrigin: '100% 50%',
					ease:            'power1.inOut',
					x:               -15,
					opacity:         0,
					onComplete:      () => {
						prices[activeIdx].style.visibility = 'hidden';
						gsap.set(prices[activeIdx], { x: 0 });
					},
				});
			},
		}, '6');
	/**
	 * Standalone tweens.
	 */

	// Turbine blades.
	gsap.to(turbineBlades, {
		rotate:          360,
		transformOrigin: '50% 50%',
		ease:            'none',
		duration:        32,
		repeat:          -1,
	});

	// Customer
	gsap.to(customer, {
		duration:        8,
		x:               25,
		transformOrigin: '100% 50%',
		repeat:          -1,
		yoyo:            true,
	});

	// Loading
	gsap.to(loading, {
		duration:        10,
		rotate:          360,
		repeat:          -1,
		transformOrigin: '50% 50%',
		ease:            'none',
	}, '<');
};

export default vCareersHero;
