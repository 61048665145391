/**
 * @file Header.
 */

import { gsap }  from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { MorphSVGPlugin } from 'gsap/MorphSVGPlugin';

/**
 * Header component.
 */
const vHeader = () => {
	const header = document.querySelector('.v-header');

	if (!header) {
		return;
	}

	gsap.registerPlugin(ScrollTrigger);
	gsap.registerPlugin(MorphSVGPlugin);

	const placeholder  = document.querySelector('.v-header__placeholder');
	const spacer       = header.querySelector('.v-header__spacer');
	const spacerHeight = spacer.getBoundingClientRect().height;
	const logoLink     = header.querySelector('.v-header__logo-link');
	const logoWidth    = logoLink.getBoundingClientRect().width;
	const logo         = header.querySelector('.v-header__logo');
	const mark         = logo.querySelector('.v-pt-logo__mark');
	const markWidth    = mark.getBoundingClientRect().width;
	const logoText     = logo.querySelector('.v-pt-logo__text');
	const secondaryCta = header.querySelector('.v-header__cta--secondary').parentElement;
	const mobileQuery  = window.matchMedia('(max-width: 1200px)');
	const menuTrigger  = header.querySelector('.v-header__nav-trigger');
	const headerTl     = gsap.timeline({ defaults: { duration: 0.3 } });

	/* HEADER SCROLL */
	ScrollTrigger.saveStyles([logoText, logoLink]);
	ScrollTrigger.matchMedia({
		'(min-width: 1201px)': () => {
			ScrollTrigger.create({
				trigger: placeholder,
				start:   'top+=10 top',
				onEnter: () => {
					header.classList.add('v-header--scrolled');
					headerTl
						.to(spacer, {
							height: 0,
						})
						.addLabel('enter')
						.to(secondaryCta, {
							y: `-${placeholder.getBoundingClientRect().height}px`,
						}, '<enter')
						.to(logoText, {
							opacity: 0,
						}, '<enter')
						.to(logoLink, {
							width: markWidth,
						}, '<+=75%');
				},
				onLeaveBack: () => {
					headerTl
						.to(spacer, {
							height: spacerHeight,
						})
						.addLabel('enterBack')
						.to(secondaryCta, {
							y: '0',
						}, '<enterBack')
						.to(logoLink, {
							width: logoWidth,
						}, '<enterBack')
						.to(logoText, {
							opacity: 1,
						}, '>-25%');
					header.classList.remove('v-header--scrolled');
				},
			});
		},
		'(max-width: 1200px)': () => {
			ScrollTrigger.create({
				trigger: placeholder,
				start:   'top+=10 top',
				onEnter: () => {
					header.classList.add('v-header--scrolled');
					headerTl.to(spacer, { height: 0 });
				},
				onLeaveBack: () => {
					headerTl.to(spacer, { height: spacerHeight });
					header.classList.remove('v-header--scrolled');
				},
			});
		},
	});

	/* HEADER MENU */
	let menuAnimating     = false;
	const mobileWrap      = header.querySelector('.v-header__mobile-nav-wrap');
	const mobileNav       = header.querySelector('.v-header__mobile-nav');
	const mobileNavClose  = header.querySelector('.v-header__mobile-nav-close');
	const mobileNavitems  = header.querySelectorAll('.v-header__mobile-nav-item');
	const mobileNavSvg    = header.querySelector('.v-header__mobile-nav-figure');
	const mobileTie       = header.querySelector('#v-menu-surf__tie-1');
	const mobileTieMorph  = header.querySelector('#v-menu-surf__tie-2');
	const mobileAnimation = gsap.timeline({
		paused:   true,
		defaults: { duration: 0.3 },
		onStart:  () => {
			mobileWrap.classList.add('v-header__mobile-nav-wrap--open');
		},
		onComplete: () => {
			menuAnimating = false;
		},
		onReverseComplete: () => {
			mobileWrap.classList.remove('v-header__mobile-nav-wrap--open');
			menuAnimating = false;
		},
	});

	mobileAnimation
		.from(mobileWrap, {
			opacity: 0,
		})
		.from(mobileNav, {
			opacity:  0,
			xPercent: 100,
		})
		.from(mobileNavClose, {
			opacity: 0,
		})
		.from(mobileNavitems, {
			opacity:  0,
			yPercent: 50,
			stagger:  0.1,
		}, '<0.1')
		.from(mobileNavSvg, {
			opacity:  0,
			xPercent: -100,
		});

	const tieAnimation = gsap.to(mobileTie, {
		morphSVG: mobileTieMorph,
		yoyo:     true,
		ease:     'none',
		repeat:   -1,
		duration: 1.5,
		paused:   true,
	});

	menuTrigger.addEventListener('click', (e) => {
		e.preventDefault();

		if (!mobileQuery.matches) {
			return;
		}

		header.classList.add('v-header--menu-open');
		menuAnimating = true;
		menuTrigger.setAttribute('aria-expanded', 'true');
		mobileAnimation.timeScale(1).restart();
		tieAnimation.play();
	});

	mobileNavClose.addEventListener('click', (e) => {
		e.preventDefault();

		if (!mobileQuery.matches || menuAnimating) {
			return;
		}

		header.classList.remove('v-header--menu-open');
		menuAnimating = true;
		menuTrigger.setAttribute('aria-expanded', 'false');
		mobileAnimation.timeScale(1.5).reverse();
		tieAnimation.reverse().pause();
	});

	mobileQuery.addEventListener('change', () => {
		const isOpen = menuTrigger.getAttribute('aria-expanded') === 'true';

		if (isOpen && !mobileQuery.matches) {
			header.classList.remove('v-header--menu-open');
			menuTrigger.setAttribute('aria-expanded', 'false');
			mobileAnimation.timeScale(1.5).reverse();
		}
	});
};

export default vHeader;
