/**
 * @file Partner Cta.
 */

import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

/**
 * Partner Cta component.
 */
const vPartnerCta = () => {
	const partnerCta = document.querySelector('.v-partner-cta');

	if (!partnerCta) {
		return;
	}

	gsap.registerPlugin(ScrollTrigger);

	const bg = partnerCta.querySelector('.v-partner-cta__bg');
	gsap.from(bg, {
		scaleX:        0.6,
		scrollTrigger: {
			trigger:       partnerCta,
			start:         'top bottom',
			end:           'center+=25% bottom',
			scrub:         0.25,
			toggleActions: 'play pause reverse reset',
		},
	});
};

export default vPartnerCta;
