/**
 * @file Homepage Hero.
 */
import { gsap }  from 'gsap';
import { MorphSVGPlugin } from 'gsap/MorphSVGPlugin';

/**
 * Homepage Hero component.
 */
const vHpHero = () => {
	const hpHero = document.querySelector('.v-hp-hero');

	if (!hpHero) {
		return;
	}

	gsap.registerPlugin(MorphSVGPlugin);

	const heroContent  = hpHero.querySelector('.v-hp-hero__content');
	const heroGraphic  = hpHero.querySelector('.v-hp-hero__graphic');
	const heroBg       = hpHero.querySelector('.v-hp-hero__background');
	const heroSvg      = hpHero.querySelector('.v-hp-hero__birds');
	const explorer     = hpHero.querySelector('#v-hp-birds__guy');
	const heroBirds    = heroGraphic.querySelector('#v-hp-birds__birds');
	const heroBubble   = heroGraphic.querySelector('.v-hp-birds__bubble[data-bird="1"]');
	const heroWindmill = heroGraphic.querySelector('#v-hp-birds__windmill-spin');
	const tieMorph     = heroGraphic.querySelector('#v-hp-birds__tie-2');
	const tieAnimation = heroGraphic.querySelector('#v-hp-birds__tie-1');
	const mobileQuery  = window.matchMedia('(max-width: 767px)');

	/**
	 * Define timelines.
	 */

	const tieTl = gsap.to(tieAnimation, {
		morphSVG: tieMorph,
		yoyo:     true,
		repeat:   -1,
		ease:     'none',
		duration: 1.5,
		paused:   true,
	});

	const birdsTl = gsap.timeline({
		defaults: {
			duration:        6,
			ease:            'power1.inOut',
			transformOrigin: '100% 50%',
		},
		yoyo:   true,
		repeat: -1,
	});

	const explorerTl = gsap.timeline({
		repeat: -1,
	});

	const mainTl = gsap.timeline({ paused: true });

	/**
	 * Add tweens.
	 */
	birdsTl
		.to('.v-hp-birds__bird[data-bird="1"]', {
			scaleY: 0.85,
		})
		.to('.v-hp-birds__bird[data-bird="2"]', {
			scaleY: 0.75,
		})
		.to('.v-hp-birds__bird[data-bird="3"]', {
			scaleY: 0.65,
		})
		.to('.v-hp-birds__bird[data-bird="4"]', {
			scaleY: 0.75,
		});

	explorerTl
		.to(explorer, {
			x:               -25,
			transformOrigin: '100% 50%',
			duration:        8,
		})
		.addLabel('explorer-move')
		.to(explorer, {
			x:               0,
			duration:        8,
			transformOrigin: '100% 50%',
		})
		.to(heroBubble, {
			scale:           1.2,
			repeat:          3,
			yoyo:            true,
			duration:        0.5,
			transformOrigin: '50% 50%',
			ease:            'power1.inOut',
		}, 'explorer-move+=1')
		.to(heroWindmill, {
			rotate:          360,
			duration:        2,
			transformOrigin: '50% 50%',
			ease:            'power1.inOut',
		}, 'explorer-move+=1');

	// main timeline
	mainTl
		.to(heroBirds, {
			y:               -10,
			duration:        3,
			ease:            'sine.inOut',
			transformOrigin: '100% 50%',
			repeat:          1,
			yoyo:            true,
		})
		.add(explorerTl, '<')
		.to(heroBirds, {
			x:               10,
			duration:        4,
			ease:            'sine.inOut',
			transformOrigin: '100% 50%',
			yoyo:            true,
			repeat:          -1,
		}, '<')
		.add(birdsTl, '<');

	/**
	 * Intro animation. Plays first.
	 */
	const introTl = gsap.timeline({
		defaults: {
			ease: 'sine.inOut',
		},
		onComplete: () => {
			mainTl.play();
			tieTl.play();
		},
	});

	if (mobileQuery.matches) {
		introTl
			.to(heroSvg, {
				opacity:  1,
				y:        0,
				duration: 1,
			})
			.to(heroBg, {
				y:        0,
				opacity:  1,
				ease:     'expo.out',
				duration: 3,
			}, '<+=.6')
			.to(explorer, {
				opacity: 1,
			}, '<')
			.to(heroContent, {
				opacity: 1,
				y:       0,
			}, '<+=.75');
	} else {
		introTl
			.to([heroContent, heroSvg], {
				opacity:  1,
				y:        0,
				duration: 1,
			})
			.to(heroBg, {
				y:        0,
				opacity:  1,
				ease:     'expo.out',
				duration: 3,
			}, '<+=.6')
			.to(explorer, {
				opacity:  1,
				duration: 1,
			}, '<');
	}
};

export default vHpHero;
