/**
 * @file Anything that can run on every page.
 */

import vCareersHero from '../components/careers-hero/careers-hero';
import vHeader from '../components/header/header';
import vHpHero from '../components/hp-hero/hp-hero';
import vHpStats from '../components/hp-stats/hp-stats';
import vPartnerCta from '../components/partner-cta/partner-cta';
// import vReviews from '../components/reviews/reviews';
import vPartnerModal from '../components/partner-modal/partner-modal';
import vQuoteSlider from '../components/quote-slider/quote-slider';

/**
 * Global functions.
 *
 * Runs component js and any global tasks.
 */
const vGlobal = () => {
	vHeader();
	vHpHero();
	vHpStats();
	// vReviews();
	vPartnerCta();
	vPartnerModal();
	vCareersHero();
	vQuoteSlider();

	const selects = document.querySelectorAll('select');
	[...selects].forEach((select) => {
		const selectParent = select.parentNode;
		if (selectParent.classList.contains('v-form__select')) {
			return;
		}

		const wrapper = document.createElement('span');
		const arrow   = document.createElement('span');
		wrapper.classList.add('v-form__select');
		arrow.classList.add('v-form__select-arrow');
		selectParent.insertBefore(wrapper, select);
		wrapper.appendChild(select);
		wrapper.appendChild(arrow);
		select.classList.add('v-form__select-field');
	});

	const radios = document.querySelectorAll('[type="radio"]');
	[...radios].forEach((radio) => {
		const radioParent = radio.parentNode;
		const radioNext   = radio.nextElementSibling;
		if (radioNext && radioNext.classList.contains('v-form__radio')) {
			return;
		}

		const customBox = document.createElement('span');
		customBox.classList.add('v-form__radio');
		radioParent.insertBefore(customBox, radio.nextSibling);
		radio.classList.add('hidden');
	});

	const lazyBgs = document.querySelectorAll('.lazy-bg');
	const lazyBgObserver = new IntersectionObserver((entries) => {
		entries.forEach((entry) => {
			if (entry.isIntersecting) {
				const lazyBg = entry.target;
				lazyBg.classList.add('lazy-bg--visible');
				lazyBgObserver.unobserve(lazyBg);
			}
		});
	});

	[...lazyBgs].forEach((lazyBg) => {
		lazyBgObserver.observe(lazyBg);
	});

	/**
	 * Global Window Load.
	 *
	 * Runs polyfills on page load.
	 */
	async function onWindowLoad() {
		const body = document.querySelector('body');
		if (!body.classList.contains('admin-bar')) {
			const { listen } = await import(/* webpackExports: "listen" */ 'quicklink');

			listen({
				ignores: [
					(url) => {
						const cleanUrl = url.replace(/#.*/, '');
						return cleanUrl === window.location.href;
					},
					/.*\/wp-admin\/.*/,
				],
			});
		}
	}

	if (document.readyState === 'complete') {
		onWindowLoad();
	} else {
		window.addEventListener('load', onWindowLoad);
	}
};

if (document.readyState !== 'loading') {
	vGlobal();
} else {
	document.addEventListener('DOMContentLoaded', vGlobal);
}
