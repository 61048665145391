/**
 * @file Homepage Stats.
 */

import { gsap }  from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

/**
 * Homepage Stats component.
 */
const vHpStats = () => {
	const hpStats = document.querySelector('.v-hp-stats');

	if (!hpStats) {
		return;
	}

	const statsWrap = hpStats.querySelector('.v-hp-stats__list');
	const stats     = hpStats.querySelectorAll('.v-hp-stats__item');

	if (!stats) {
		return;
	}

	gsap.registerPlugin(ScrollTrigger);

	ScrollTrigger.create({
		trigger:       statsWrap,
		start:         'bottom bottom',
		toggleActions: 'play none none reset',
		onEnter:       () => {
			const tl = gsap.timeline({
				defaults: {
					ease:     'power1.inOut',
					stagger:  0.1,
					duration: 0.25,
				},
			});
			stats.forEach((stat) => {
				tl.to(stat, {
					scale:  1.2,
					yoyo:   true,
					repeat: 1,
				}, '>-25%');
			});
		},
	});
};

export default vHpStats;
