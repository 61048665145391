/**
 * @file Quote Slider.
 */

import Swiper, { EffectCreative } from 'swiper';

/**
 * Quote Slider component.
 */
const vQuoteSlider = () => {
	const quoteSlider = document.querySelector('.v-quote-slider');

	if (!quoteSlider) {
		return;
	}

	// const quotes  = quoteSlider.querySelectorAll('.v-quote-slider__quote');
	const btnPrev = quoteSlider.querySelector('.v-quote-slider__button--prev');
	const btnNext = quoteSlider.querySelector('.v-quote-slider__button--next');
	const dots    = quoteSlider.querySelectorAll('.v-quote-slider__dot-button');
	const prevLarge =  {
		shadow:    true,
		translate: ['-120%', 0, -500],
		origin:    ['50%', '50%'],
	};
	const nextLarge = {
		shadow:    true,
		translate: ['120%', 0, -500],
		origin:    ['50%', '50%'],
	};

	const slider = new Swiper('.v-quote-slider__slider-track', {
		modules:        [EffectCreative],
		slidesPerView:  1,
		loop:           true,
		initialSlide:   0,
		effect:         'creative',
		centeredSlides: true,
		spaceBetween:   16,
		creativeEffect: {
			prev: {
				translate: [0, 0, -200],
				origin:    ['50%', '50%'],
			},
			next: {
				shadow:    true,
				translate: ['100%', 0, 0],
				origin:    ['50%', '50%'],
			},
		},
		breakpoints: {
			768: {
				slidesPerView:  1.5,
				creativeEffect: {
					prev:               prevLarge,
					next:               nextLarge,
					limitProgress:      1,
					shadowPerProgress:  true,
					progressMultiplier: 0.25,
				},
			},
			1024: {
				slidesPerView:  2.5,
				creativeEffect: {
					prev:               prevLarge,
					next:               nextLarge,
					limitProgress:      2,
					shadowPerProgress:  true,
					progressMultiplier: 0.25,
				},
			},
		},
	});

	btnPrev.addEventListener('click', (e) => {
		e.preventDefault();

		if (slider.animating) {
			return;
		}

		slider.slidePrev();
	});

	btnNext.addEventListener('click', (e) => {
		e.preventDefault();

		if (slider.animating) {
			return;
		}

		slider.slideNext();
	});

	[...dots].forEach((dot) => {
		dot.addEventListener('click', (e) => {
			e.preventDefault();

			if (slider.animating) {
				return;
			}

			const slideIndex = parseInt(dot.getAttribute('data-slide'), 10);

			slider.slideToLoop(slideIndex);
		});
	});

	slider.on('slideChange', () => {
		const activeSlide = slider.realIndex;

		[...dots].forEach((dot) => {
			dot.parentElement.classList.remove('v-quote-slider__dot--active');
		});

		dots[activeSlide].parentElement.classList.add('v-quote-slider__dot--active');
	});
};

export default vQuoteSlider;
