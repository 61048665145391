/**
 * @file Partner Modal.
 */

import { gsap } from 'gsap';

/**
 * Partner Modal component.
 */
const vPartnerModal = () => {
	const partnerModal = document.querySelector('.v-partner-modal');

	if (!partnerModal) {
		return;
	}

	const main      = document.querySelector('.v-main');
	const triggers  = document.querySelectorAll('[aria-controls="v-partner-modal"]');
	const close     = partnerModal.querySelector('.v-partner-modal__close');
	const container = partnerModal.querySelector('.v-partner-modal__container');
	let isAnimating = false;
	const tl        = gsap.timeline({
		defaults: {
			duration: 0.4,
			ease:     'power1.inOut',
		},
		paused:  true,
		onStart: () => {
			partnerModal.classList.add('v-partner-modal--open');
			isAnimating = true;
		},
		onReverseComplete: () => {
			partnerModal.classList.remove('v-partner-modal--open');
			partnerModal.setAttribute('aria-hidden', 'true');
			isAnimating = false;
		},
		onComplete: () => {
			main.classList.add('v-main--blur');
			partnerModal.focus();
			partnerModal.setAttribute('aria-hidden', 'false');
			isAnimating = false;
		},
	})
		.from(partnerModal, {
			scale:   0.9,
			opacity: 0,
		})
		.from(container, {
			scale:   0.9,
			opacity: 0,
		})
		.from(close, {
			scale:   0.9,
			opacity: 0,
		});

	[...triggers].forEach((trigger) => {
		trigger.addEventListener('click', (e) => {
			e.preventDefault();

			if (isAnimating) {
				return;
			}

			const { target } = e;
			target.setAttribute('v-previous-focus', '');

			[...triggers].forEach((t) => {
				t.setAttribute('aria-expanded', 'true');
			});

			tl.play();
		});
	});

	close.addEventListener('click', (e) => {
		e.preventDefault();

		if (isAnimating) {
			return;
		}

		const previousFocus = document.querySelector('[v-previous-focus]');
		if (previousFocus) {
			previousFocus.focus();
		}
		main.classList.remove('v-main--blur');

		[...triggers].forEach((trigger) => {
			trigger.setAttribute('aria-expanded', 'false');
		});

		tl.reverse();
	});
};

export default vPartnerModal;
